import React, { useState, useContext, useEffect } from "react";
import { CloseCircleOutlined, ExportOutlined, FileOutlined, LogoutOutlined, QuestionCircleOutlined, PaperClipOutlined, MenuUnfoldOutlined, MenuFoldOutlined, BellOutlined } from '@ant-design/icons';
import { Layout, Menu, message, Divider, Row, Col, Button, Drawer, Badge, Pagination } from 'antd';
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "img/logo-nav.png";
import { UserContext } from 'components/context/user-context';

import accediAreaDocentiNC from "img/icons/accedi-area-docente-nc.svg";
import approvazioneDocentiNC from "img/icons/approvazione-docenti-nc.svg";
import corsiDiFormazioneNC from "img/icons/corsi-di-formazione-nc.svg";
import attivazioneSaggioNC from "img/icons/attivazione-saggio-nc.svg";
import bibliotecaBiblitecheNC from "img/icons/biblioteca-bibliotecheNC.svg";
import materialePropagandaNC from "img/icons/materiale-propaganda-nc.svg";
import registrazioneIncontriNC from "img/icons/registrazione-incontri-nc.svg";
import portfolioDocentiNC from "img/icons/portfolio-docenti-nc.svg";
import registrazioneDocentiNC from "img/icons/registrazione-docenti-nc.svg";
import ilTechnologicoNC from "img/icons/il-technologicoNC.svg";
import newsletter from "img/icons/newsletterNC.svg";
import listaNC from "img/icons/listaNC.svg";
import agenteNC from "img/icons/agenteNC.svg";
import libreriaLattesNC from "img/icons/libreriaLattesNC.svg";
import lezionInCorsoNC from 'img/icons/lezioni-in-corso-nc.svg';
import labCompetenzeNC from 'img/icons/laboratorio-italianoNC.svg';
import sitoIstituzionaleNC from 'img/icons/latteseditoriNC.svg';
import magazineNC from 'img/icons/lattes-magazineNC.svg';
import musicPlayerNC from 'img/icons/music-playerNC.svg';
import ambienteSNC from 'img/icons/logo-ambiente-scienze-NC.png';
import ambienteGrammaticaNC from 'img/icons/matita-ambiente-grammatica-NC.svg';
import ambientePiuNC from 'img/icons/logo-ambiente-piu-NC.svg';
import ambienteDNC from 'img/icons/ambiente-dNC.svg';
import bSmart from 'img/icons/bSmart.svg';
import logoBG from "img/logo-bg.svg";
import { sendRequest } from 'components/utils/axios.config';

import { useMediaQuery } from 'react-responsive';
import "./template.css";
import { empty } from "components/utils/utils";




const { Header, Sider, Content, Footer } = Layout;

const Notifica = ({ dati, showDrawerDettaglioNotifica, aggiornaListaNotifiche }) => {


    const changeLetto = (id, letto) => {
        sendRequest.patch('/agente_messaggio/' + id, {
            letto: letto ? 0 : 1
        }).then(resp => {
            message.success('Notifica impostata come ' + (letto ? 'da leggere' : 'letta'));
            aggiornaListaNotifiche();
        });
    };

    return <div className='notifica'>
        <Row >
            <Col span={16}>
                <div onClick={() => showDrawerDettaglioNotifica(dati)} >
                    <div style={{ fontSize: '18px', fontWeight: '600' }} className={"titolo-notifica"} >
                        {dati.oggetto}
                    </div>
                    <p>{dati.data}</p>
                </div>
            </Col>
            <Col span={4}>{!empty(dati.allegati) ? <PaperClipOutlined /> : ''}</Col>
            <Col span={4}>
                <Button type="link" className="badge" onClick={() => changeLetto(dati.id, parseInt(dati.letto))}>
                    {parseInt(dati.letto) ? <span className="badge-circle"></span> : <span className="badge-dot"></span>}
                </Button>
            </Col>
        </Row>
    </div >;
}

function Template(props) {

    const isSmartphone = useMediaQuery({ maxWidth: 768 });

    const [collapsed_slider, setCollapedSlider] = useState(isSmartphone);
    const [notifiche, setNotifiche] = useState([]);
    const [numero_notifiche_non_lette, setNumeroNotificheNonLette] = useState(0);
    const [notifica_selezionata, setNotificaSelezionata] = useState({});
    const [pagina_notifiche, setPaginaNotifiche] = useState(1);
    const [drawer_notifiche_visibile, setDrawerNotificheVisible] = useState(false);
    const [drawer_dettaglio_notifica_visibile, setDrawerDettaglioNotificaVisible] = useState(false);

    let drawerWidth = !collapsed_slider ? "35vw" : "100vw";

    let user = useContext(UserContext);
    let location = useLocation();
    let history = useHistory();

    const aggiornaListaNotifiche = () => {
        numeroNotificheNonLette();

        sendRequest.get('/commerciale/notifiche?pagina=' + pagina_notifiche).then(resp => {
            setNotifiche(resp);
        });
    }

    const showDrawerNotifiche = () => {
        setDrawerNotificheVisible(true);
    };

    const onCloseDrawerNotifiche = () => {
        setDrawerNotificheVisible(false);
    }

    const onCloseDrawerDettaglioNotifica = () => {
        setDrawerDettaglioNotificaVisible(false);
    }

    const showDrawerDettaglioNotifica = (notifica) => {
        sendRequest.patch('/agente_messaggio/' + notifica.id, {
            letto: 1
        }).then(resp => {
            aggiornaListaNotifiche();
        });
        setNotificaSelezionata(notifica);
        setDrawerDettaglioNotificaVisible(true);
    };

    var toggle = () => {
        setCollapedSlider(!collapsed_slider);
    }

    var mobileCollapse = () => {
        if (isSmartphone) setCollapedSlider(true);
    }

    const logout = () => {
        sendRequest.get('/commerciale/logout').then(resp => {
            if (resp.result === 'ok') {
                localStorage.setItem("logged", 'false');
                user.updateUserContext({});
                history.push("/login");
            } else if (resp.result === 'ko') {
                message.error(resp.error);
            }
        });
    }

    const onChangePagination = (page) => {
        setPaginaNotifiche(page);
        document.querySelector(".drawer-notifiche .ant-drawer-body").scrollTo({ top: 0, behavior: 'smooth' });
    }

    const scaricaAllegato = (documento) => {
        const url = process.env.REACT_APP_SERVER_URL + '/commerciale/scarica-allegato?file=' + documento;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        // a.download = 'todo-1.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    useEffect(() => {
        aggiornaListaNotifiche();
    }, [pagina_notifiche]);


    const numeroNotificheNonLette = () => {
        sendRequest.get('/commerciale/notifiche/non-lette').then(resp => {
            setNumeroNotificheNonLette(resp);
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            numeroNotificheNonLette();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Layout>
                <img src={logoBG} alt="" id="logoBg" />
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed_slider}
                    collapsedWidth={isSmartphone ? '0' : '80'}
                    width="270"
                    style={{ zIndex: 2 }}
                    className="sider-menu"
                >
                    <Link to="/" style={{
                        textAlign: 'center'
                    }}>

                        <div className="logo" style={{ height: '64px', padding: '10px' }}>
                            <img src={logo} alt="" className="logo" />

                        </div>
                    </Link>
                    {isSmartphone ? <div style={{ textAlign: 'right' }}>
                        <CloseCircleOutlined
                            className="close-menu-icon"
                            onClick={toggle}
                            style={{
                                fontSize: "20px",
                                padding: "15px",
                                color: 'white'
                            }} />
                    </div> : ''}
                    <Divider style={{ margin: 0 }} />
                    {/* Aree operative */}
                    <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
                        {!collapsed_slider ? <h3 style={{ textAlign: 'center', padding: '40px 0', fontSize: '25px' }}>Aree Operative</h3> : ""}
                        <Menu.Item key="/portfolio-docenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/portfolio-docenti" onClick={mobileCollapse}>
                                <img src={portfolioDocentiNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Portfolio Docenti</span>
                            </Link>
                        </Menu.Item>
                        {(empty(user.info.id_agente)) && <Menu.Item key="/agenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/agenti" onClick={mobileCollapse}>
                                <img src={agenteNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Agenti</span>
                            </Link>
                        </Menu.Item>}
                        <Menu.Item key="/registrazione-docenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/registrazione-docenti" onClick={mobileCollapse}>
                                <img src={registrazioneDocentiNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Registrazione Docenti</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/approvazione-docenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/approvazione-docenti" onClick={mobileCollapse}>
                                <img src={approvazioneDocentiNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Approvazione Docenti</span>
                            </Link>
                        </Menu.Item>
                        <Menu.SubMenu
                            key="/saggi/attivazione"
                            title={
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/saggi/attivazione" onClick={mobileCollapse}>
                                    <img src={attivazioneSaggioNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Attivazione Saggi</span>
                                </Link>
                            }
                        >
                            <Menu.Item key="/saggi/storico">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/saggi/storico" onClick={mobileCollapse}>Storico assegnazioni</Link>
                            </Menu.Item>
                        </Menu.SubMenu>


                        <Menu.Item key="/materiale-propaganda">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/materiale-propaganda" onClick={mobileCollapse}>
                                <img src={materialePropagandaNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Materiale propaganda</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/registrazione-incontri">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/registrazione-incontri" >
                                <img src={registrazioneIncontriNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Registrazione Incontri</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/liste">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/liste" onClick={mobileCollapse}>
                                <img src={listaNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Liste</span>
                            </Link>
                        </Menu.Item>
                        <Menu.SubMenu
                            key="/newsletter"
                            title={
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter" onClick={mobileCollapse}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={newsletter} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                        <span>Newsletter</span>
                                    </span>
                                </Link>
                            }
                        >
                            <Menu.Item key="/newsletter/nuovo-invio">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/nuovo-invio" onClick={mobileCollapse}>Nuova</Link>
                            </Menu.Item>

                            <Menu.Item key="/newsletter/inviate">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/inviate" onClick={mobileCollapse}>Inviate</Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/coda">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/coda" onClick={mobileCollapse}>Coda di invio</Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/statistiche">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/statistiche" onClick={mobileCollapse}>Statistiche generali</Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/impostazioni">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/impostazioni" onClick={mobileCollapse}>Impostazioni</Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.Item key="/corsi-di-formazione">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/corsi-di-formazione" onClick={mobileCollapse}>
                                <img src={corsiDiFormazioneNC} alt="" className={'icon-sider anticon anticon-unordered-list'} />
                                <span>Corsi di formazione</span>
                            </Link>
                        </Menu.Item>
                    </Menu>
                    {/* Fine aree operative */}

                    <Divider style={{ borderTop: '1px solid #fff' }} />

                    {/* Link utili */}
                    <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
                        {!collapsed_slider ? <h3 style={{ textAlign: 'center', padding: '40px 0', fontSize: '25px' }}>Link Utili</h3> : ""}
                        {/* Libreria Lattes */}
                        {/* <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://www.latteseditori.it/libreria-lattes" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={libreriaLattesNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Libreria Lattes</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item> */}
                        {/* Teachbox */}
                        <Menu.Item>
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} target="_blank" href="https://teachbox.latteseditori.it/" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={accediAreaDocentiNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Teachbox</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}> <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* bSmart */}
                        <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://www.bsmart.it/" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={bSmart} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>bSmart</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>

                            </a>
                        </Menu.Item>
                        {/* Sito istituzionale */}
                        <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://www.latteseditori.it/" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={sitoIstituzionaleNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Sito istituzionale</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>

                            </a>
                        </Menu.Item>
                        {/* Lezioni in corso */}
                        <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://www.latteseditori.it/lezioniincorso" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={lezionInCorsoNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Lezioni In Corso</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>

                            </a>
                        </Menu.Item>
                    </Menu>
                    {/* Fine link utili */}

                    <Divider style={{ borderTop: '1px solid #fff' }} />

                    {/* Ambienti digitali */}
                    <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
                        {!collapsed_slider ? <h3 style={{ textAlign: 'center', padding: '40px 0', fontSize: '25px' }}>Ambienti digitali</h3> : ""}
                        {/* Esercizi di grammatica  */}
                        <Menu.Item>
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://iscrizioniscienze.lattes.app/" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={ambienteSNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Ambiente Scienze</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* Ambiente Grammatica  */}
                        <Menu.Item>
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://ambientegrammatica.lattes.app/" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={ambienteGrammaticaNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Ambiente Grammatica</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* Esercizi di grammatica  */}
                        {/* <Menu.Item>
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="http://studenti.latteseditori.it/" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={grammaticaNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Esercizi di grammatica  </span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item> */}
                        {/* Laboratorio delle competenze */}
                        <Menu.Item>
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} target="_blank" href="https://laboratorioitaliano.lattes.app/" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={labCompetenzeNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Laboratorio delle competenze</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}> <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* Ambiente D */}
                        <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://iscrizionidisegno.lattes.app/" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={ambienteDNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Ambiente D</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* Ambiente Più */}
                        <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://matematica.lattes.app/docente/login" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={ambientePiuNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Ambiente +</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* Music Player */}
                        <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} href="https://lattesplayer.web.app/check-license" target="_blank" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={musicPlayerNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Music Player</span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* Biblioteca delle Biblioteche */}
                        <Menu.Item >
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} target="_blank" href="https://portale.lattes.app/docente/biblioteca-delle-biblioteche" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}><img src={bibliotecaBiblitecheNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Biblioteca delle Biblioteche </span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                    </Menu>
                    {/* Fine ambienti digitali */}

                    <Divider style={{ borderTop: '1px solid #fff' }} />

                    {/* Blog */}
                    <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
                        {!collapsed_slider ? <h3 style={{ textAlign: 'center', padding: '40px 0', fontSize: '25px' }}>Blog</h3> : ""}
                        {/* il Technologico  */}
                        <Menu.Item>
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} target="_blank" href="https://iltechnologico.it/" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}><img src={ilTechnologicoNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>il Technologico   </span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}>  <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                        {/* Lattes Magazine */}
                        <Menu.Item>
                            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} target="_blank" href="https://www.latteseditori.it/blog/" rel="noopener noreferrer">
                                <div style={{ display: 'flex', alignItems: 'center' }}> <img src={magazineNC} alt="" width="40" height="40" className={'icon-sider anticon anticon-unordered-list'} />
                                    <span>Lattes Magazine  </span></div><span style={{ display: 'flex', justifyContent: 'flex-end' }}> <ExportOutlined /></span>
                            </a>
                        </Menu.Item>
                    </Menu>
                    {/* Fine Blog */}
                </Sider>
                <Layout >
                    <Header>
                        <Row >
                            <Col xs={8} md={10}>
                                {React.createElement(collapsed_slider ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggle,
                                    style: {
                                        textAlign: 'left',
                                        fontSize: "20px",
                                        padding: "0 15px"
                                    }
                                })}
                            </Col>
                            <Col xs={8} md={0}>
                                <Link to="/"><img src={logo} alt="" className="logo" /></Link>
                            </Col>
                            <Col xs={4} md={10} style={{ textAlign: 'right' }}>
                                <Badge count={numero_notifiche_non_lette}>
                                    <Button className={'btn-notifiche'} onClick={showDrawerNotifiche} type={'primary'} icon={<BellOutlined />}>{!isSmartphone ? 'CENTRO NOTIFICHE' : ''}</Button>
                                </Badge>
                            </Col>
                            <Col xs={4} md={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Button onClick={logout} type='text' className="logout" style={{ textAlign: 'right', color: 'inherit' }}>
                                    {!isSmartphone ? <span>LOGOUT</span> : ""}
                                    <LogoutOutlined
                                        style={{
                                            fontSize: "20px",
                                            padding: "0 5px",
                                            verticalAlign: 'sub'
                                        }} />
                                </Button>
                            </Col>
                        </Row>



                    </Header>
                    <Content
                        style={{
                            margin: '15px 15px 0px',
                            minHeight: "calc(100vh - 148px)"
                        }}
                        className="container-content"
                        id="main-content"
                    >
                        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                            {isSmartphone ?
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/tutorial" onClick={mobileCollapse}>
                                    <Button shape={'circle'} className="help" size="large" style={{ background: '#eee' }} icon={<QuestionCircleOutlined />} /></Link> :
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/tutorial" onClick={mobileCollapse}><Button size="large" className="help">Serve aiuto <QuestionCircleOutlined /> </Button></Link>}
                        </div>
                        {props.children}
                    </Content>
                    <Footer style={{ textAlign: 'center', background: '#eee' }}>
                        S. LATTES &amp; C. EDITORI S.p.A.<br />
                        Via Confienza, 6 - 10121 Torino<br />
                        Codice Fiscale e Partita Iva: 04320600010<br />
                        Iscrizione Registro Imprese di Torino n. 04320600010

                    </Footer>
                    <Row justify="center" style={{ width: '100%', background: '#f7f7f7' }}>
                        <span style={{ padding: '10px 20px', fontSize: 11 }}>Engineering and design by <a href="https://www.sernicola-labs.com/" target="_blank" rel="noopener noreferrer">Sernicola Labs Srl</a></span>
                    </Row>
                </Layout>
            </Layout>
            <Drawer
                className="drawer-notifiche"
                width={drawerWidth}
                placement="right"
                closable={false}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type='danger' onClick={onCloseDrawerNotifiche}>CHIUDI</Button>
                    </div>
                }
                onClose={onCloseDrawerNotifiche}
                visible={drawer_notifiche_visibile}
            >
                <h2>Notifiche</h2>
                <Divider />
                {!empty(notifiche) && notifiche.records.map((notifica) => (
                    <Notifica
                        key={notifica.id}
                        dati={notifica}
                        showDrawerDettaglioNotifica={showDrawerDettaglioNotifica}
                        aggiornaListaNotifiche={aggiornaListaNotifiche}
                    />
                ))}
                <Pagination
                    total={notifiche.total}
                    current={pagina_notifiche}
                    pageSize={notifiche.page_size}
                    showSizeChanger={false}
                    onChange={onChangePagination}
                    style={{ textAlign: 'right', marginTop: 15 }}
                />
            </Drawer>
            <Drawer
                title={
                    <h3>{notifica_selezionata.oggetto}</h3>
                }
                width={350}
                closable={false}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type='danger' onClick={onCloseDrawerDettaglioNotifica}>CHIUDI</Button>
                    </div>
                }
                onClose={onCloseDrawerDettaglioNotifica}
                visible={drawer_dettaglio_notifica_visibile}
                key={notifica_selezionata.id}
            >
                {!empty(notifica_selezionata.allegati) ?
                    <>
                        <Row>
                            {notifica_selezionata.allegati.map((documento) => (

                                <Col key={documento}>
                                    <Button icon={<FileOutlined />} onClick={() => scaricaAllegato(documento)}>{documento}</Button>
                                </Col>

                            ))}
                        </Row>
                        <Divider />
                    </>
                    :
                    ''}
                <p>{notifica_selezionata.testo}</p>
                <Divider />
                <p style={{ textAlign: 'right' }}>{notifica_selezionata.data}</p>
            </Drawer>
        </>
    );
}

export default Template;